import React from 'react';
import './ImageParallax.css'; // CSS file for styling
import { Link } from 'react-router-dom';


const ImageParallax = () => {
  return (
    <div className="image-section">
      <div className="parallax">
        <div className="image-text">
          <p>Welcome to JBJM AI and Cloud Pvt Ltd, where innovation meets excellence in the realms of Artificial Intelligence (AI) and Cloud Computing.</p>
          <Link to="Contact" className="btn btn-outline-dark contact1">Contact Us</Link>
       
      
        </div>
      </div>
    </div>
  );
};

export default ImageParallax;
