import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './Career.css'; // Import custom CSS file
import GetTouch from './GetTouch';




const JobCard = ({ job }) => {
  const handleApply = () => {
    window.location.href = 'mailto:hr@jbjmaicloud.com';
  };

  return (
    <Card className="job-card">
      <Card.Body>
        <Card.Title>{job.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{job.location}</Card.Subtitle>
        <Card.Text>{job.description}</Card.Text>
        <Button onClick={handleApply} variant="primary" className="Apply-button">Apply</Button>
      </Card.Body>
    </Card>
  );
};

const Career = () => {
  const jobs = [
    {
      title: 'DevOps Engineer',
      location: 'Pune',
      description: '0-4 Years',
    },
    {
      title: 'Frontend Developer',
      location: 'Pune',
      description: '0-4 Years',
    },
    {
      title: 'Backend Developer',
      location: 'Pune.',
      description: '0-4 Years',
    },
    {
      title: 'Cloud Integration Developer',
      location: 'Pune.',
      description: '0-4 Years',
    },

    {
      title: 'DBA',
      location: 'Pune.',
      description: '0-4 Years',
    },
    // Add more job objects as needed
  ];

  const handleExploreJobOpenings = () => {
    const section = document.getElementById('jobOpenings');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <div className="video-container">
  <video autoPlay loop muted className="">
    <source src="video/Green Gradient IT Solution Services Profile Video (7).mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7">
          <img src="Image\campaign.jpg" alt="Your Image" className="img-fluid" />
        </div>
        <div className="col-md-5">
          <h1>Join the team</h1>
          <p>We're on a mission to empower everyone to build the web. If you're passionate about the web, we'd love to have you on our team.</p>
          <h3>Why work with us</h3>
          <p>
            We're building the platform for the next generation of digital experiences.
          </p>
          <p>
            Our team is distributed, diverse, and dedicated to creating seamless collaboration for the world.
          </p>
          <p>
            We value curiosity, iteration, and the human side of software development.
          </p>
          <button className="job-openings-button" onClick={handleExploreJobOpenings}>Explore Job Openings</button>
        </div>
      </div>
       <br></br>
       <br></br>
       <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="text-center">Benefits</h1>
                    <h4 className="text-center">We're committed to providing our team with the support and resources they need to thrive.</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="benefit-card">
                        <h2>Flexible Work Environment</h2>
                        <p>We believe in the power of flexibility. Whether you're most productive in the office, at home, or on the go, we support your work style.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="benefit-card">
                        <h2>Professional Development</h2>
                        <p>We're committed to helping our team grow. You'll have access to resources for learning new skills, attending conferences, and more.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="benefit-card">
                        <h2>Health & Wellness</h2>
                        <p>Your well-being is important to us. We offer comprehensive health benefits, mental health resources, and fitness perks to help you stay healthy and happy.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="benefit-card">
                        <h2>Team Collaboration</h2>
                        <p>Great ideas can come from anywhere. You'll be part of a collaborative team that values diverse perspectives and fosters innovation.</p>
                    </div>
                </div>
            </div>
        </div>

       <br></br>
      <br></br>
      <h2 className='car'>Current job openings</h2>
      <br></br>
      <div className="row" id="jobOpenings">
        {jobs.map((job, index) => (
          <div key={index} className="col-md-4 mb-4">
            <JobCard job={job} />
          </div>
        ))}
      </div>
        <br></br>
        <br></br>
     
            <br></br>
            
      
    </div>
    

    <GetTouch></GetTouch>

<br></br>




   
    </>
  );
};

export default Career;
