import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutoCarousel.css'

const AutoCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} pause={false} className="auto-carousel">
      <Carousel.Item>
        <div className="carousel-item-content">
          <img
            className="d-block w-100"
            src="Image/cloud12.jpg"
            alt="First slide"
            style={{ maxHeight: "600px" }} // Adjust the max height here
          />
          <div className="carousel-caption">
            <h3 className="carousel-heading">JBJM AI and Cloud</h3>
            <p className="carousel-description">Artificial Intelligence and Cloud Computing Platform</p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-item-content">
          <img
            className="d-block w-100"
            src="Image\ai56.jpg"
            alt="Second slide"
            style={{ maxHeight: "600px" }} // Adjust the max height here
          />
          <div className="carousel-caption">
            <h3 className="carousel-heading">JBJM AI and Cloud</h3>
            <p className="carousel-description">Artificial Intelligence and Cloud Computing Platform</p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-item-content">
          <img
            className="d-block w-100"
            src="Image/cloud3.jpg"
            alt="Third slide"
            style={{ maxHeight: "600px" }} // Adjust the max height here
          />
          <div className="carousel-caption">
            <h3 className="carousel-heading">JBJM AI and Cloud</h3>
            <p className="carousel-description">Artificial Intelligence and Cloud Computing Platform</p>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default AutoCarousel;
