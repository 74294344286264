import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Import your custom CSS file
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <>
      <div className="container-fluid back bg-dark">
        <div className="row">
          <div className="col-lg-5">
            <h2>JBJM AI and Cloud Pvt Ltd</h2>
      
            <div className="unorder">
              <span><i className="fab fa-instagram"></i></span>
              &nbsp;
              <span><i className="fab fa-facebook"></i></span>
              &nbsp;
              <span><i className="fab fa-google-plus-square"></i></span>
              &nbsp;
              <span><i className="fab fa-twitter-square"></i></span>
            </div>
          </div>

          <div className="col-lg-4 Footertext">
            <h5 className="mt-4">LOCATION</h5>
          
            <p>
              <b>Address:</b> IT-7 Building,Qubix Business Parks,<br></br>Phase 1,Hinjawadi,Pune,Maharashtra 411057.
            </p>
            <p>
              <span><b>Contact Number:</b> +91-8805544789</span>  <br />
            </p>
            
          </div>
      

        <div className="col-lg-3 Footertext">
            <h5 className="mt-4">CONTACT</h5>
      
            <p>
              <span><b>Email:</b><Link to="mailto:support@jbjmaicloud.com" className='redirct'>support@jbjmaicloud.com</Link></span>
              <br></br>
              <Link to="mailto:hr@jbjmaicloud.com" className='redirct rect '>hr@jbjmaicloud.com</Link>
            </p>
            <p>
              <span><b>Website:</b> <Link to="http://www.jbjmaicloud.com" className='redirct'>www.jbjmaicloud.com</Link></span>
            </p>
      </div>
      </div>
     </div>

    

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p className="demo5"> &copy; Copyright <strong>JBJM AI and Cloud Pvt Ltd</strong>. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
