// Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Navcss.css';

function NavSection() {
  return (
    <Navbar className='container-fluid' collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand as={Link} to="/"><img
          src="Image\logo.png"
          className="logo"
          alt="Your Logo"
        /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link className="NavLink" as={Link} to="/">Home</Nav.Link>
          <Nav.Link className="NavLink" as={Link} to="/about">About</Nav.Link>
          <Nav.Link className="NavLink" as={Link} to="/contact">Contact</Nav.Link>
          <Nav.Link className="NavLink" as={Link} to="/services">Services</Nav.Link>
          <Nav.Link className="NavLink" as={Link} to="/career">Career</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavSection;
