import React from 'react';
import './ProjectsComponent.css';

const ProjectsComponent = () => {
  return (
    <div className="container third mt-4">
      <h2 className="heading">Our Successful Projects</h2>
      <div className="row project-row">
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>Running Vehicle Speed & Tire Press
              ure Measurement System (IoT & Azure Cloud)</h3>
            <p>Engineered a comprehensive system for real-time measurement of running vehicle
              speed and tire pressure. This solution enhances safety and performance monitoring for
              vehicles, contributing to overall road safety.</p>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>IOT & AI Computation Solution for UK-based Smart Metering (Azure & Google cloud)</h3>
            <p>Implemented a sophisticated IoT and AI computation solution to enhance smart metering
              capabilities in the UK. Our innovative approach optimized data processing, improving efficiency
              and accuracy in energy consumption monitoring.</p>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>Cargo Management Software for Airlines (Azure Cloud)</h3>
            <p>Developed a robust cargo management software tailored for the aviation industry. This solution
              streamlined logistics operations, ensuring efficient tracking, scheduling, and management of
              cargo for airlines.</p>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>Warehouse Cargo Detection & Image Processing with Data Detection (Azure Cloud)</h3>
            <p>Introduced an advanced warehouse solution integrating RFID and IoT technologies for missing
              cargo detection. Implemented AI-based image processing and data detection solutions for
              cargo. This project aimed at improving user experience and data accuracy through innovative
              artificial intelligence techniques.</p>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>150+ Projects Migrated from On-Premise to Cloud Platform</h3>
            <p>Successfully migrated over 150+ projects from on-premise infrastructure to cloud platforms.</p>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="info-card animated">
            <h3>DevOps and DevSecOps Solution Implementation for 20+ Organizations</h3>
            <p>Our expertise in continuous integration, delivery, and security integration optimized
              development pipelines, ensuring faster and secure software delivery.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsComponent;
