import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './NotFound.css';

function NotFound() {
  return (
    <div className="not">
      <h1 className="error">404</h1>
      <div className="page">Ooops!!! The page you are looking for is not found</div>
      {/* Use Link to navigate to the home route */}
      <Link to="/" className="back-home">Back to home</Link>
    </div>
  );
}

export default NotFound;
