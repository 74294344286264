import React from 'react';
import AutoCarousel from './AutoCarousel';
import Approach from './Approch';
import MyCard from './MyCard';
import InfoCard from './InfoCard';
import ProjectsComponent from './ProjectsComponent';
import ImageParallax from './ImageParallax';


function Home() {
  return (
    <>
      <AutoCarousel></AutoCarousel>
      <Approach></Approach>
      <InfoCard></InfoCard>
      <br></br>
      <MyCard></MyCard>
      <ImageParallax></ImageParallax>
      <br></br>
      <ProjectsComponent></ProjectsComponent>
      <br></br>

      
    </>
  );
}

export default Home;
