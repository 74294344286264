import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Component/Home';
import AboutSection from './Component/AboutSection';
import Contact from './Component/Contact';
import Services from './Component/Services';
import NavSection from './Component/NavSection';
import Footer from './Component/Footer';
import Career from './Component/Career';
import NotFound from './Component/NotFound';
import './App.css';



function App() {
  return (

    
    <Router>
      <div>
        {/* Render the NavSection component */}
        <NavSection></NavSection>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
          {/* Route for handling 404 errors */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer></Footer>
      </div>
    </Router>
    
  );
}

export default App;
