import React from 'react';
import { Link } from 'react-router-dom';
import './Approch.css';

const Approch = () => {
  return (
    <div className="container-fluid app my-5">
      <div className="row">
        <div className="col-lg-6">
          {/* Embedding a video using the <video> element */}
          <video className="w-100 shadow" autoPlay muted loop>
            <source src="video/J.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="col-lg-6">
          <div className="p-3">
            <h2 className="heading">Our Approach</h2>
            <p className="lead">
              At JBJM AI and Cloud, we believe in a client-centric approach that prioritizes understanding
              unique business challenges and goals. Our tailored solutions are designed to not only meet
              but exceed expectations. We collaborate closely with our clients, ensuring transparency,
              flexibility, and a seamless integration of our technology solutions into their workflows.
            </p>
            <Link to="/about" className="btn  btn-custom btn-outline-dark">About Us</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approch;
