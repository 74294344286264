import React from 'react'
import './GetTouch.css'

function GetTouch() {
  return (
    
    <div className="container-fluid add ">
  <div className="content mt-5">
    <span className='demo99'>CONTACT US</span>
    <h2>Get In Touch</h2>
    <p>Phone <br></br>
    +91-8805544789 </p>
    <p>Email <br></br>
     support@jbjmaicloud.com
     <br></br>
     hr@jbjmaicloud.com</p>
    
    <p>Address <br></br> 
    IT-7 Building, Qubix Business Parks, Phase 1,<br></br> Hinjawadi, Pune, Maharashtra 411057</p>
    
  </div>
  <div className="video mt-5">
  <video className=" shadow" autoPlay muted loop>
            <source src="video\Green Gradient.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
  </div>
  </div>

  )
}

export default GetTouch
