import React from 'react';
import './Services.css'; // Import your CSS file if needed
import { Container, Row, Col } from 'react-bootstrap';
import GetTouch from './GetTouch';


const Services = () => {
  return (
    <>
         <div className="video-background container-fulid">
  <video autoPlay loop muted>
    <source src="video/Blue and White Illustrative Modern Science and Technology Education Video.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <div className="overlay">
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col md={10}>
          <h1 className='head45 text-center'>Our Expertise</h1>
          <p className='head46 text-center'>"JBJM AI and Cloud specializes in providing Azure DevOps services, delivering tailored solutions to optimize digital application development and deployment processes for our clients."</p>
        </Col>
      </Row>
    </Container>
  </div>
</div>












    <div className="row service container-fluid mt-5">
      <div className="heading-container">
        <h2 className="heading5">Our Azure DevOps engineer will bring expertise in the following areas</h2>
      </div>
    <br></br>
        
      {/* Service Cards */}
      <div className="column demo90 mt-5">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Continuous Integration/Continuous Deployment (CI/CD)</h3>
          <p className="paragraph">
            Setting up and maintaining robust CI/CD pipelines on Azure to streamline the software development lifecycle.
          </p>
        </div>
      </div>

      <div className="column demo90 mt-5">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Infrastructure as Code (IaC)</h3>
          <p className="paragraph">
            Implementing infrastructure automation using tools such as Terraform or Ansible to ensure consistency and scalability on Azure Cloud.
          </p>
        </div>
      </div>

      <div className="column demo90 mt-5">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Containerization</h3>
          <p className="paragraph">
            Utilizing containerization technologies like Docker to enhance application portability and deployment efficiency.
          </p>
        </div>
      </div>

      <div className="column demo90">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Monitoring & Logging</h3>
          <p className="paragraph">
            Implementing effective monitoring and logging solutions to proactively identify and address issues.
          </p>
        </div>
      </div>

      <div className="column demo90">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Collaboration & Communication</h3>
          <p className="paragraph">
            Facilitating collaboration between development and operations teams through tools like Slack, Microsoft Teams, or other communication platforms.
          </p>
        </div>
      </div>

      <div className="column demo90">
        <div className="servicecard">
          <div className="icon-wrapper">
            <i className="fa fa-cloud"></i>
          </div>
          <h3 className="head1">Migration of Application to Cloud</h3>
          <p className="paragraph">
            Our DevOps engineer, who brings extensive expertise in facilitating the seamless migration of local .NET applications to cloud-based environments. We ensure a smooth and secure migration process and unlock the full potential of cloud infrastructure for improved performance and scalability applications.
          </p>
        </div>
      </div>



    
    </div>

    <br></br>
   <GetTouch></GetTouch>
    <br></br>

    </>

    
   
  );
};

export default Services;
