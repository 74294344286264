import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Card from 'react-bootstrap/Card';
import './Card.css'; // Import your CSS file

const MyCard = () => {
  return (
    <div className='mt-5'>
      <h2 className='heading'>Our Expertise</h2>
      <br></br>
      <br></br>
      <div className="card-container">
        <Card className="card1">
          <Card.Img variant="top" src="Image\Artificial-Intelligence.jpeg" alt="Card image" />
          <Card.Body>
            <Card.Title>Artificial Intelligence (AI)</Card.Title>
            <Card.Text>
              We specialize in developing AI-driven applications and solutions that enhance efficiency, automate processes, and unlock new possibilities for our clients. From machine learning algorithms to natural language processing, our AI expertise spans a wide spectrum.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="card1">
          <Card.Img variant="top" src="Image\cloud computing.jpg" alt="Card image" />
          <Card.Body>
            <Card.Title>Cloud Computing</Card.Title>
            <Card.Text>
              Embrace the limitless possibilities of the Azure & google cloud with our comprehensive cloud computing services. Whether you're looking to migrate to the Azure, Google & AWS cloud, optimize existing cloud infrastructure, or develop cloud-native applications, we have the expertise to make it happen.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="card1">
          <Card.Img variant="top" src="Image\migration.jpg" alt="Card image" />
          <Card.Body>
            <Card.Title>Migration of Applications to Cloud</Card.Title>
            <Card.Text>
              Seamlessly transition your applications to the Azure, AWS & Google cloud with our expert migration services. Whether you're looking to move existing systems or develop new, cloud-native applications, we ensure a smooth and secure migration process.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <br></br>
    </div>
  );
};

export default MyCard;
