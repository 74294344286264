import React from 'react';
import './InfoCard.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const InfoCard = () => {
  return (
    <div className="container auto">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {/* Info Card with Animation */}
          <div className="info-card1 animate__animated animate__fadeIn">
            <h2>Why Choose JBJM AI and Cloud</h2>
            <p><strong>Innovation:</strong> We stay at the forefront of technological advancements, ensuring that our clients benefit from the latest innovations in AI and cloud computing.</p>
            <p><strong>Expert Team:</strong> Our team comprises seasoned professionals with diverse skill sets, ensuring comprehensive coverage across AI and all cloud domains (Azure, AWS & Google).</p>
            <p><strong>Custom Solutions:</strong> We recognize that every business is unique. Our solutions are customized to address specific needs, fostering growth and adaptability.</p>
            <p><strong>Commitment to Excellence:</strong> We are committed to delivering excellence in every project, setting high standards for quality, security, and performance.</p>
            <Link to="Contact" className="btn btn-outline-dark">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
